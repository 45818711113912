<!--
 * @Descripttion:
 * @version:
 * @Author: 高涛
 * @Date: 2021-10-25 15:19:14
 * @LastEditors: 高涛
 * @LastEditTime: 2021-12-13 13:33:14
-->
<template>
<a-modal v-model="visible" :title="form.id? '编辑' : '新增'" @ok="handleOk" :footer="null">
  <a-form-model
    ref="ruleForm"
    :model="form"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol">
        <br/>
        <a-form-model-item label="版面名称" prop="name">
            <a-input placeholder="请输入" v-model="form.name"/>
        </a-form-model-item>
        <a-form-model-item label="版面编号" prop="code">
            <a-input placeholder="请输入" v-model="form.code"/>
        </a-form-model-item>
        <a-form-model-item label="排序" prop="sort">
            <a-input-number placeholder="请输入" v-model="form.sort" :min="1" />
        </a-form-model-item>
        <!-- 是否启用 -->
        <a-form-model-item label="是否启用">
            <a-radio-group name="radioGroup" v-model="form.status">
                <a-radio :value=1>启用</a-radio>
                <a-radio :value=0>不启用</a-radio>
            </a-radio-group>
        </a-form-model-item>
        <!-- 栏目描述 -->
        <a-form-item label="备注">
            <a-textarea v-model="form.memo" placeholder="请输入备注" :rows="4"/>
        </a-form-item>
        <!-- 底部按钮 -->
        <a-form-item :wrapper-col="{ span: 14, offset: 6 }">
            <a-button type="primary" @click.prevent="onSubmit">提交</a-button>
            <a-button style="margin-left: 10px" @click="resetFields">取消</a-button>
        </a-form-item>
        <br/>
  </a-form-model>
</a-modal>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      value: undefined,
      treeData: [],
      options: [
        {
          value: 'jack',
          label: 'Jack'
        },
        {
          value: 'lucy',
          label: 'Lucy'
        }
      ],
      rules: {
        name: [
          { required: true, message: '请填写版面名称', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请填写版面编号', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '请填写排序', trigger: 'blur' }
        ]
      },
      form: {
        id: '',
        name: '',
        code: '',
        sort: undefined,
        status: 1,
        memo: ''
      },
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 14
      }
    }
  },
  watch: {
    value (value) {
      console.log(value)
    }
  },
  created () {
  },
  methods: {
    handleOk () {
      console.log('OK')
    },
    resetForm () {
      this.form = {
      }
    },
    async init ({ id, record }) {
      console.log(id, record, 'init')
      this.btClickCount = 0
      if (id) this.form.id = id

      const { name, code, sort, status, memo } = { ...record }
      this.form.id = id || undefined
      this.form.name = name
      this.form.code = code
      this.form.sort = sort
      this.form.status = status || 1
      this.form.memo = memo

      this.visible = true
    },
    onSubmit () {
      if (this.btClickCount === 0) {
        this.btClickCount++
        setTimeout(() => {
          this.btClickCount = 0
        }, 1000)
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.submitForm()
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    async submitForm () {
      let apiStr = ''
      apiStr = this.form.id ? 'updateScene' : 'addScene'
      console.log(apiStr, '12341234')
      const res = await this.$store.dispatch('http', {
        api: apiStr,
        params: this.form
      })
      console.log(res)
      if (res) {
        this.$store.dispatch('showToast', { type: 'success', message: '操作成功' })
        this.visible = false
        this.$emit('ok')
      }
    },
    handleChange () {
      console.log('handlechange')
    },
    resetFields () {
      console.log('resetFields')
      this.visible = false
    }
  }
}
</script>

<style>

</style>
